
import { Options, Vue } from 'vue-class-component';
import LoginPage from '@/components/LoginPage.vue'; // @ is an alias to /src

@Options({
  components: {
    LoginPage,
  },
})
export default class HomeView extends Vue {}
