<template>
  <div class="back" style="width: 100%;left: 0;top: -80px;height: calc(100% + 80px);z-index: 9999;">
    <!-- <div class="title">
      因士设备管理中台大屏
    </div> -->
    <!-- <div class="content">
      <div style="height: 460px;width: 480px;border: 3px solid #00eaff;">
        <div style="line-height: 10vh;font-size:2rem;color:#00eaff;text-align: center;">
          账号登录
        </div>
        <div style="height: calc(45% - 240px)"></div>
        <div>
          <input style="background: rgba(255,255,255,0);text-align: center; border: 1px solid;color: #00eaff;height: 2rem;font-size: 1.2rem;width: 60%;margin-top:10vh;margin-left: 20%;" v-model="data.account"/>
        </div>
        <div>
          <button style="width: 62%;height: 2rem;font-size: 1.2rem;background: rgba(255,255,255,0); border: 1px solid;color: #00eaff;height: 2rem;margin-top:8vh;cursor:pointer;margin-left: 20%;" @click="commit">登录</button>
        </div>
      </div>
    </div> -->
    <div class="form-cont">
        <div class="form-top">
            <div class="form-title">
                VOCs泄漏精准追踪展示平台
            </div>
        </div>
        <div class="form-input-box">
            <div class="user-logo">
                <!-- <img src="../../assets/images/login/logo.png" alt=""> -->
            </div>
            <div class="form-group">
            <label class="form-label">
                <el-icon :size="20"><User /></el-icon>
            </label>
            <input type="text" class="form-control" v-model="data.user" placeholder="请输入账号">
            </div>
            <div class="form-group">
            <label class="form-label">
                <el-icon :size="20"><Lock /></el-icon>
            </label>
            <input type="password" class="form-control" v-model="data.password" placeholder="请输入密码">
            </div>
            <button type="submit" class="btn btn-success btn-block btn-lg" @click="commit">登录</button>
        </div>
    </div> 
  </div>
</template>
<script>
import { reactive } from '@vue/reactivity';
import { useRouter } from "vue-router";
import { login } from '@/request/apis/user'
import axios from 'axios'
import { onMounted } from '@vue/runtime-core';
export default {
  name: 'helloWorld',
  props: {
      msg: String
    },
  setup() {
    // 实现全屏的方法
    const requestFullScreen = (element) => {
      if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullScreen) {
                element.webkitRequestFullScreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.msRequestFullscreen) {
                // IE11
                element.msRequestFullscreen();
            }
      // var requestMethod = element.requestFullScreen || //W3C
      //   element.webkitRequestFullScreen || //Chrome
      //   element.mozRequestFullScreen || //FireFox
      //   element.msRequestFullScreen; //IE11
      //   console.log('requestMethod', requestMethod)
      // if (requestMethod) {
      //   requestMethod.call(element);
      // } else if (typeof window.ActiveXObject !== "undefined") { //for Internet Explorer
      //   var wscript = new ActiveXObject("WScript.Shell");
      //   console.log('wscript', wscript)
      //   if (wscript !== null) {
      //     wscript.SendKeys("{F11}");
      //   }
      // }
    }
    onMounted(() => {
      // requestFullScreen(document.documentElement);
    })
    const data = reactive({
      account: 'test',
      user: '',
      password: '',
    })
    const router = useRouter();
    const commit = () => {
        if (!data.user) {
            alert('请输入账号')
            return false
        }
        if (!data.password) {
            alert('请输入密码')
            return false
        }
        if (data.user === 'bwtybs') {
          if (data.password == '123456') {
            let param = {
            'identifier': 'bwtybjq',
            'password': 'da4016e0fa7f89b1a2452f623249d75c'
            }
            axios.post('open/users/login',param)
            .then((response) => {
              console.log('open/users')
              localStorage.setItem('token', response.data.access_token)
              router.push({
                path: "/heatMap"
              });
              requestFullScreen(document.documentElement);
            })
            // login(param).then( res => {
            //   localStorage.setItem('token', res.access_token)
            //   router.push({
            //     path: "/heatMap"
            //   });
            //   requestFullScreen(document.documentElement);
            // })
          } else {
            alert('密码错误')
          }
        } else {
          alert('账号错误')
        }
    }
    return {
      commit,
      data
    }
  }
}
</script>
<style>
.input {
  border: 0px;
  outline: 0px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.back {
  background-image: url('../assets/login/login-bg.png');
  height: 100%;
  width: 100%;
  background-size: 100%;
}
.title {
    height: 10vh;
    line-height: 10vh;
    text-align: center;
    color: #00eaff;
    font-size: 2.5rem;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
  }
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.form-cont {
    position: fixed;
    width: 350px;
    left: 50%;
    top: 50%;
    margin-top: -189px;
    margin-left: -175px;
    background: rgba(255,255,255,0.3);
    border-radius: 20px;
    overflow: hidden;
    .form-top {
        height: 120px;
        color: #fff;
        .form-title {
            text-align: center;
            padding-top: 20px;
            font-size: 18px;
            letter-spacing: 0.2rem;
        }
    }
    .form-input-box {
        position: relative;
        padding-top: 80px;
        background: #c0c1cb;
        .user-logo {
            background: url('../assets/login/login-bg.png');
            background-size: 100% 100%;
            position: absolute;
            width: 80px;
            height: 80px;
            top: -40px;
            left: 50%;
            margin-left: -40px;
            border-radius: 50%;
            overflow: hidden;
        }
        .form-group {
            position: relative;
            margin-left: 10px;
            margin-right: 40px;
            padding-left: 30px;
            align-items: center;
            display: flex;
            .form-label {
                margin-right: 20px;
            }
            .form-control {
                text-align: center;
                background: transparent;
                border: none;
                -webkit-box-shadow: none;
                box-shadow: none;
                border-bottom: 2px solid #fff;
                border-radius: 0;
                color: #fff;
            }
            .form-control:focus {
              border: none;
            }
        }
        .btn-success {
            margin-top: 50px;
            background: #45b0a8;
            border: none;
            border-radius: 0;
        }
    }
}
.input:focus {
              border-color: #4cae4c !important;
            }
.input {
  border: 0px;
  outline: 0px;
}
.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.form-control:focus {
              border: none;
            }
.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
.btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
}
.btn-block {
    display: block;
    width: 100%;
}
.btn-lg {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
}
</style>
