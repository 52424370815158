// 导入axios实例
import httpRequest from '@/request/index'
// 获取用户信息
export function login(param: any) {
    return httpRequest({
		url: 'open/users/login',
		method: 'post',
		data: param,
	})
}

export function getDevices(param: any) {
  return httpRequest({
  url: 'open/devices?order_by=mn&sort_type=ASC',
  method: 'get',
  data: param,
})
}
export function getDevicesData(param: any,uuid: string) {
  return httpRequest({
  url: 'open/device_datas/data?start=' + param['start'] + '&end=' + param['end'] + '&device_uuid=' + uuid,
  method: 'get',
  data: param,
})
}
export function getDevicesDatas(param: any,uuid: string) {
  return httpRequest({
  url: 'open/device_datas?sort_type=ASC'+ '&page_index='+ param['page_index'] + '&page_size=' + param['page_size'] +'&start=' + param['start'] + '&end=' + param['end'] + '&order_by=createdAt'
  + '&device_uuid=' + uuid,
  method: 'get',
  data: param,
})
}

